import React, { FC, useEffect, useState, useRef, MouseEvent } from 'react';
import {
  checkSupport,
  needCreateSubscription,
  initNotification
} from 'scripts/notification';
import styles from './PushNotification.module.css';
import { Category } from 'types';

const PushNotification: FC<{
  classes?: string;
  onClose?: () => void;
  onCheckSub?: (message?: string) => void;
}> = ({ classes, onClose, onCheckSub }) => {
  const [openPushModal, setOpenPushModal] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);

  const tripsCheckbox = useRef<HTMLInputElement>(null);
  const eventsCheckbox = useRef<HTMLInputElement>(null);

  const askNotification = async () => {
    const supportPush = await checkSupport();
    if (!supportPush) {
      if (onCheckSub)
        onCheckSub(
          'Ihre Browserversion unterstützt leider die Push-Benachrichtigung nicht.'
        );
      return;
    }

    const needCreate = await needCreateSubscription();
    if (!needCreate) {
      if (onCheckSub)
        onCheckSub('Sie haben schon die Push-Benachrichtigung aboniert');
      return;
    }

    setOpenPushModal(true);
    if (onCheckSub) onCheckSub();
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      askNotification();
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  const closeModal = () => {
    if (openPushModal) setOpenPushModal(false);
    if (openSuccessModal) {
      setOpenSuccessModal(false);
      if (onClose) onClose();
    }
  };

  const noClickHandler = () => {
    if (onClose) onClose();
    closeModal();
  };

  const backdropClick = (event: MouseEvent<HTMLDivElement>) => {
    if (event.target === event.currentTarget) closeModal();
  };

  const initPushNotification = async () => {
    let category;

    let trips = false;
    let events = false;
    if (tripsCheckbox.current) trips = tripsCheckbox.current.checked;
    if (eventsCheckbox.current) events = eventsCheckbox.current.checked;

    if (trips && events) category = 'all';
    else if (trips) category = 'trips';
    else if (events) category = 'events';
    else return;

    closeModal();
    const subscribeResult = await initNotification(category as Category);
    if (!!subscribeResult && subscribeResult.success) setOpenSuccessModal(true);
  };

  const pushModalContent = (
    <>
      <div className={styles.ModalContent}>
        <p>
          Wir nutzen Ihre Browser-ID, um Ihnen Push-Notification zuzuschicken.
        </p>
        <div className={styles.Categories}>
          <p>
            Wählen Sie bitte über welche Ereignisse wollen Sie informiert
            werden:
          </p>
          <label htmlFor="trips">
            <input
              type="checkbox"
              name="trips"
              id="trips"
              defaultChecked
              ref={tripsCheckbox}
            />
            &nbsp; Fahrten
          </label>
          <label htmlFor="events">
            <input
              type="checkbox"
              name="trips"
              id="events"
              defaultChecked
              ref={eventsCheckbox}
            />
            &nbsp; Veranstaltungen
          </label>
        </div>
        <p>
          Um die Mitteilungen zu erhalten, müssen Sie anschließend Browser Push
          Nachrichten erlauben. Die Zustimmungsmeldung erscheint bei Desktop
          Computern links oben unterhalb der Adressleiste als Sprechblase. Bei
          Mobilen Geräten (Android etc.) erscheint die Meldung als Box am
          unteren Bildschirmrand.
        </p>
      </div>
      <footer className={styles.ModalFooter}>
        <button
          className={`${styles.ModalAction} ${styles.ModalCancel}`}
          onClick={noClickHandler}
        >
          Nein, danke
        </button>
        <button
          className={`${styles.ModalAction} ${styles.ModalConfirm}`}
          onClick={initPushNotification}
        >
          OK
        </button>
      </footer>
    </>
  );

  const successModalContent = (
    <>
      <div className={styles.ModalContent}>
        <p>
          Vielen Dank! Sie wurden erfolgreich für Push-Notification angemeldet.
        </p>
        <p>
          Ab jetzt werden Sie alle neue Meldungen, die zu den ausgewählten
          Kategorien gehören, auch per Push kriegen.
        </p>
      </div>
      <footer className={styles.ModalFooter}>
        <button
          className={`${styles.ModalAction} ${styles.ModalConfirm}`}
          onClick={closeModal}
        >
          OK
        </button>
      </footer>
    </>
  );

  if (openPushModal || openSuccessModal)
    return (
      <div
        className={`${styles.ModalWrapper}${classes ? ' ' + classes : ''}`}
        onClick={backdropClick}
      >
        <section className={styles.Modal}>
          <header className={styles.ModalHeader}>
            <h2>Push-Nachrichten von Spiekeroog Info erhalten?</h2>
          </header>
          {openPushModal ? pushModalContent : successModalContent}
        </section>
      </div>
    );
  return null;
};

export default PushNotification;
