import React, { FC } from 'react';
import styles from './PushSection.module.css';
import { Link } from 'react-router-dom';

const PushSection: FC = () => {
  return (
    <section className={styles.Section}>
      <h2>Bekommen Sie keine Push-Nachrichten?</h2>
      <p>
        <Link to="/push-test" className={`${styles.Action}`}>
          → Push-Benachrichtigungen testen
        </Link>
      </p>
    </section>
  );
};

export default PushSection;
