import React, { FC, useState, ChangeEvent } from 'react';
import styles from './RouteSection.module.css';
import RouteTable from './RouteTable/RouteTable';
import { format, getNextDay, isLater } from 'helpers/dates';

const route = {
  from: {
    id: 'from',
    portFrom: 'Spiekeroog',
    portTo: 'Neuharlingersiel',
    portFromId: 2,
    portToId: 1
  },
  to: {
    id: 'to',
    portFrom: 'Neuharlingersiel',
    portTo: 'Spiekeroog',
    portFromId: 1,
    portToId: 2
  }
};

const RouteSection: FC = () => {
  const [selectedRoute, setSelectedRoute] = useState(route.to);
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(getNextDay());

  const handleSelectRoute = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedRoute(route[event.target.id as 'from' | 'to']);
  };

  const handleChangeFromDate = (value: Date) => {
    setFromDate(value);
    if (isLater(value, toDate)) setToDate(value);
  };

  return (
    <section className={styles.RouteSection}>
      <div className={styles.Route}>
        <h2>Fahrtrichtung:</h2>
        <div className={styles.RouteToggle}>
          <label
            className={`${styles.ToggleButton} ${
              selectedRoute.id === 'to' ? styles.selected : ''
            }`}
          >
            <input
              type="radio"
              name="route"
              id="to"
              checked={selectedRoute.id === 'to'}
              onChange={handleSelectRoute}
              className="hidden"
            />
            {route.to.portFrom} → {route.to.portTo}
          </label>
          <label
            className={`${styles.ToggleButton} ${
              selectedRoute.id === 'from' ? styles.selected : ''
            }`}
          >
            <input
              type="radio"
              name="route"
              id="from"
              checked={selectedRoute.id === 'from'}
              onChange={handleSelectRoute}
              className="hidden"
            />
            {route.from.portFrom} → {route.from.portTo}
          </label>
        </div>
      </div>
      <div className={styles.Schedule}>
        <section className={styles.Routes}>
          <h2>Hinfahrt</h2>
          <RouteTable
            key="from"
            title={`ab ${selectedRoute.portFrom}`}
            portFrom={selectedRoute.portFromId}
            portTo={selectedRoute.portToId}
            date={fromDate}
            onDateChange={handleChangeFromDate}
          />
        </section>
        <section className={styles.Routes}>
          <h2>Rückfahrt</h2>
          <RouteTable
            key="to"
            title={`ab ${selectedRoute.portTo}`}
            portFrom={selectedRoute.portToId}
            portTo={selectedRoute.portFromId}
            date={toDate}
            minDate={fromDate}
            onDateChange={setToDate}
          />
        </section>
      </div>

      <div className={styles.Footer}>
        <a
          href={`https://buchung.spiekeroog.de/?hin_date=${format(
            fromDate,
            'dd.MM.yyyy'
          )}&rueck_date=${format(toDate, 'dd.MM.yyyy')}`}
          className="button"
        >
          Zur Buchung
        </a>
      </div>
    </section>
  );
};

export default RouteSection;
