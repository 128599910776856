import _format from 'date-fns/format';
import de from 'date-fns/locale/de';

export const format = function(date: Date, formatStr: string) {
  return _format(date, formatStr, {
    locale: de
  });
};

export const isLater = (date1: string | Date, date2: string | Date) => {
  return new Date(date1).getTime() > new Date(date2).getTime();
};

const addDays = (_date: string | Date, days: number) => {
  const date = new Date(_date);
  date.setDate(date.getDate() + days);
  return date;
};

export const getPreviousDay = (date: string | Date = new Date()) => {
  return addDays(date, -1);
};

export const getNextDay = (date: string | Date = new Date()) => {
  return addDays(date, 1);
};

export const isSameDay = (d1: Date, d2: Date) => {
  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
  );
};
