import React, { FC, useRef, useEffect } from 'react';
import styles from './CalendarSection.module.css';

const CalendarSection: FC = () => {
  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    if (iframeRef.current && iframeRef.current.dataset.src) {
      iframeRef.current.src = iframeRef.current.dataset.src;
    }
  }, []);

  return (
    <section className={styles.EventsCalendar}>
      <h2>Veranstaltungen</h2>
      <div className={styles.IframeWrapper}>
        <iframe
          ref={iframeRef}
          data-src="https://veranstaltungskalender.spiekeroog.de/"
          title="Veranstaltungskalender"
        ></iframe>
      </div>
    </section>
  );
};

export default CalendarSection;
