import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Header from 'layout/Header/Header';
import Footer from 'layout/Footer/Footer';
import StatusPage from 'components/StatusPage/StatusPage';
import Setup from 'components/Setup/Setup';
import TestPush from 'components/StatusPage/TestPushNotification';

function App() {
  return (
    <>
      <Header />
      <Router>
        <Switch>
          <Route path="/push-setup" component={Setup} />
          <Route path="/push-test" component={TestPush} />
          <Route path="/" component={StatusPage} />
        </Switch>
      </Router>
      <Footer />
    </>
  );
}

export default App;
