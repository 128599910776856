import { useState, useCallback } from 'react';
import { BACKEND_URL, APIKEY } from 'configs/api';
import axios from 'axios';

// ToDO: types for hook
type StrapiAPIHook = () => any[];

const useStrapiAPI: StrapiAPIHook = () => {
  const [response, setResponse] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const callAPI = useCallback(
    async (
      url: string,
      method: 'GET' | 'POST' = 'GET',
      options: any = {},
      data: any = {}
    ) => {
      setError('');

      try {
        setLoading(true);

        return await axios(BACKEND_URL + url, {
          method: method,
          params: {
            ...options,
            apikey: APIKEY
          },
          data: data,
          headers: {}
        })
          .then(response => {
            setResponse(response);
            setLoading(false);
            return response;
          })
          .catch(error => {
            let errorMessage = error;
            if (error.response) {
              errorMessage =
                typeof error.response.data === 'string'
                  ? error.response.data
                  : typeof error.response.data.message === 'string'
                  ? error.response.data.message
                  : error.response.data.message.error_message;
            } else {
              errorMessage =
                'Es ist ein Fehler aufgetreten. Daten können nicht abgerufen werden';
            }
            setError(errorMessage);
            setLoading(false);
            return { error: errorMessage };
          });
      } catch (e) {
        setError(e);
        setLoading(false);
        return { error: e };
      }
    },
    []
  );

  return [{ response, loading, error }, callAPI];
};

export default useStrapiAPI;
