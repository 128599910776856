import React, { FC } from 'react';
import CalendarSection from 'components/CalendarSection/CalendarSection';
import NotificationSection from 'components/NotificationSection/NotificationSection';

const Schedule: FC<{ id: string }> = ({ id }) => {
  return (
    <section id={id}>
      <CalendarSection />
      <NotificationSection title="Veranstaltungsinformationen" type={2} />
    </section>
  );
};

export default Schedule;
