import React, { FC } from 'react';
import styles from './Header.module.css';
import Logo from './spiekeroog_logo.jpg';

const Header: FC = () => {
  return (
    <header className={styles.Header}>
      <img src={Logo} alt="Spiekeroog Logo" className={styles.Logo} />
    </header>
  );
};

export default Header;
