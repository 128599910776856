import React, { FC } from 'react';
import styles from './StatusPage.module.css';
import Schedule from './Schedule/Schedule';
import Events from './Events/Events';
import PushSection from './PushSection/PushSection';
import PushNotification from '../PushNotification/PushNotification';

const LinkKey = {
  schedule: 'Fahrplan',
  events: 'Veranstaltungen'
};

const StatusPage: FC = () => {
  return (
    <main className={styles.Main}>
      <div className={styles.PageHeader}>
        <h1>Spiekeroog Info</h1>
      </div>
      <div className={styles.Content}>
        <a href={`#${LinkKey.schedule}`} className={`button ${styles.Action}`}>
          Zum Fahrplan
        </a>
        <a href={`#${LinkKey.events}`} className={`button ${styles.Action}`}>
          Zu den Veranstaltungen
        </a>

        <Schedule id={LinkKey.schedule} />
        <Events id={LinkKey.events} />
        <PushSection />
      </div>
      <PushNotification />
    </main>
  );
};

export default StatusPage;
