import React, { FC } from 'react';
import styles from './Footer.module.css';

const Footer: FC = () => {
  return (
    <footer className={styles.Footer}>
      <ul className={styles.FooterLinks}>
        <li className={styles.Item}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.spiekeroog.de/kontakt/"
          >
            Kontakt
          </a>
        </li>
        <li className={styles.Item}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.spiekeroog.de/impressum/"
          >
            Impressum
          </a>
        </li>
        <li className={styles.Item}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.spiekeroog.de/datenschutz/"
          >
            Datenschutz
          </a>
        </li>
        <li className={styles.Item}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.spiekeroog.de/presse/"
          >
            Presse
          </a>
        </li>
      </ul>
    </footer>
  );
};

export default Footer;
