import React, { FC, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import styles from './Setup.module.css';
import PushNotification from '../PushNotification/PushNotification';
import { LoadingEllipsis as Loading } from 'components/Loading/LoadingEllipsis';

const Setup: FC = () => {
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('');
  const history = useHistory();

  const onCloseHandler = () => {
    history.push('/');
  };

  const onCheckSubHandler = (message?: string) => {
    setLoading(false);
    if (message) setMessage(message);
  };

  return (
    <main
      className={`${styles.Main} ${loading ? ' ' + styles.Main_loading : ''}`}
    >
      {loading && <Loading />}
      <PushNotification
        classes={styles.Modal}
        onClose={onCloseHandler}
        onCheckSub={onCheckSubHandler}
      />
      {message.length > 0 && (
        <div className={styles.Content}>
          <p>{message}</p>
          <p>
            <Link to="/" className={styles.Link}>
              → Zur Spiekeroog Infoseite
            </Link>
          </p>
        </div>
      )}
    </main>
  );
};

export default Setup;
