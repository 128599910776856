import { BACKEND_URL, APIKEY } from 'configs/api';
import axios from 'axios';
import { Category } from 'types';

type ResponseType = {
  success: boolean;
  error: string;
};

const publicVapidKey =
  'BHjOXdQ2x-zU3xy4U8ZHws65Sk_aZFrsrcPNjeIhy4kWMjJEkCFHxRYOY7gOrQjIwtDbezjD1oQHfLyctYeZlpI';

export const initNotification = async (category: Category) => {
  const permission = await askForPermission();
  if (!permission)
    return { success: false, error: 'We do not have permissions' };
  const subscribeResult = await configurePushSubscription(category);
  return subscribeResult;
};

export const checkSupport = async () => {
  let support = 'Notification' in window && 'serviceWorker' in navigator;
  if (!support) return false;

  try {
    const swreg = await navigator.serviceWorker.ready;
    if (!swreg || swreg.pushManager === undefined) return false;
    else return support;
  } catch (error) {
    return false;
  }
};

export const checkPermission = () => {
  return 'Notification' in window && Notification.permission === 'granted';
};

export const needCreateSubscription = () => {
  // if (!navigator.serviceWorker.controller) return null;
  return navigator.serviceWorker.ready
    .then(swreg => {
      return swreg.pushManager.getSubscription();
    })
    .then(sub => {
      return sub === null;
    })
    .catch(error => {
      console.error(error);
      return null;
    });
};

const getSubscription = async () => {
  return navigator.serviceWorker.ready
    .then(swreg => {
      return swreg.pushManager.getSubscription();
    })
    .then(sub => {
      return sub;
    })
    .catch(error => {
      console.error(error);
      return null;
    });
};

const askForPermission = () => {
  return Notification.requestPermission(result => {
    return result === 'granted';
  });
};

const urlBase64ToUint8Array = (base64String: string) => {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; i += 1) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

const configurePushSubscription = (category: Category) => {
  const convertedVapidPublicKey = urlBase64ToUint8Array(publicVapidKey);
  return navigator.serviceWorker.ready
    .then(swreg => {
      return swreg.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: convertedVapidPublicKey
      });
    })
    .then(subscription => {
      const sub = JSON.parse(JSON.stringify(subscription));
      const subscriptionData = {
        ...sub,
        category
      };
      return axios(BACKEND_URL + '/subscribe', {
        method: 'POST',
        params: {
          apikey: APIKEY
        },
        data: JSON.stringify(subscriptionData),
        headers: {
          'Content-Type': 'application/json'
        }
      });
    })
    .then(response => {
      if (!!response.data) return response.data as any;
      else return response as any;
    })
    .catch(error => {
      console.error(error);
      return { success: false, error };
    });
};

export const checkSubscription = async () => {
  const sub = await getSubscription();
  if (sub === null) {
    const createRequest = await configurePushSubscription('all');
    return createRequest.success;
  } else {
    return axios(BACKEND_URL + '/testSubscription', {
      method: 'POST',
      params: {
        apikey: APIKEY
      },
      data: JSON.stringify(sub),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        if (!!response && !!response.data) return response.data.success;
        else return false;
      })
      .catch(error => {
        console.error(error);
        return false;
      });
  }
};

export const testPushSubscription = async () => {
  const sub = await getSubscription();
  if (sub === null) {
    console.log('no subscription');
  } else {
    return axios(BACKEND_URL + '/testPush', {
      method: 'POST',
      params: {
        apikey: APIKEY
      },
      data: JSON.stringify(sub),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        if (!!response && !!response.data) return response.data as any;
        else return response as any;
      })
      .catch(error => {
        console.error(error);
        return error;
      });
  }
};
