import React, { FC } from 'react';
import RouteSection from 'components/RouteSection/RouteSection';
import NotificationSection from 'components/NotificationSection/NotificationSection';

const Schedule: FC<{ id: string }> = ({ id }) => {
  return (
    <section id={id}>
      <RouteSection />
      <NotificationSection title="Statusmeldungen" type={1} />
    </section>
  );
};

export default Schedule;
