import React, { FC } from 'react';
import styles from './Route.module.css';
import { format } from 'helpers/dates';

const Route: FC<{
  data: any; // ToDO
}> = ({ data }) => {
  const utilizationClass =
    data.utilization.passengers > 66.7
      ? data.utilization.passengers > 97.5
        ? styles.red
        : styles.orange
      : styles.green;
  return (
    <li className={styles.Route}>
      <div className={styles.Time}>
        {format(new Date(data.departure), 'HH:mm')} Uhr
      </div>
      <div className={styles.Craft}>
        <div className={`${styles.Utilization} ${utilizationClass}`}></div>
        <div className={styles.Name}>{data.craft.name}</div>
      </div>
    </li>
  );
};

export default Route;
