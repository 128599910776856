import { useState, useCallback } from 'react';
import axios from 'axios';
import { R2_URL } from 'configs/api';

// ToDO: types for hook
type R2APIHook = () => any[];

type Headers = {
  Authorization?: string;
  'Content-Type': string;
};

const useR2API: R2APIHook = () => {
  const [response, setResponse] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const callAPI = useCallback(
    async (
      url: string,
      method: 'GET' | 'POST' = 'GET',
      options: any,
      data: any
    ) => {
      setError('');
      try {
        const headers: Headers = {
          'Content-Type': 'application/json'
        };
        setLoading(true);

        return await axios(R2_URL + url, {
          method: method,
          params: options,
          data: data,
          headers: headers
        })
          .then(response => {
            setResponse(response);
            setLoading(false);
            return response;
          })
          .catch(error => {
            console.log(error);
            let errorObj = error;
            if (error.response) {
              errorObj = error.response.data;
              if (typeof errorObj === 'object' && error.response.data.message) {
                errorObj = error.response.data.message;
              }
            }
            setError(errorObj.toString());
            setLoading(false);
            return { error: errorObj };
          });
      } catch (e) {
        setError(e);
        setLoading(false);
        console.log(e);
        return { error: e };
      }
    },
    []
  );

  return [{ response, loading, error }, callAPI];
};

export default useR2API;
