import React, { FC, useState, useRef, useEffect } from 'react';
import styles from './RouteTable.module.css';
import Route from './Route/Route';
import useR2API from 'hooks/useR2Api';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import de from 'date-fns/locale/de';
import { getNextDay, getPreviousDay, isLater, isSameDay } from 'helpers/dates';
registerLocale('de', de);

const RouteTable: FC<{
  title: string;
  portFrom: number;
  portTo: number;
  date: Date;
  minDate?: Date;
  onDateChange: (value: Date) => void;
}> = ({
  title,
  portFrom,
  portTo,
  date,
  minDate = new Date(),
  onDateChange
}) => {
  const [routes, setRoutes] = useState([]);
  const [{ response, loading, error }, callAPI] = useR2API();
  const dateInputRef = useRef<DatePicker>(null);

  useEffect(() => {
    const _date = date;
    _date.setHours(0, 0, 0);
    callAPI('/trip', 'GET', {
      port: portFrom,
      toPort: portTo,
      fromDate: _date.toISOString(),
      type: 1 // Linie trips
    });
  }, [callAPI, date, portFrom, portTo]);

  useEffect(() => {
    if (response && response.data) {
      let trips = response.data;
      trips = trips.filter((item: any) => {
        return new Date(item.departure).getDate() === date.getDate();
      });
      setRoutes(trips);
    }
  }, [response]);

  const handleCalendarClick = () => {
    if (dateInputRef.current) dateInputRef.current.setFocus();
  };

  const handlePrevClick = () => {
    if (!isLater(minDate, date) && !isSameDay(minDate, date))
      onDateChange(getPreviousDay(date));
  };

  const handleNextClick = () => {
    onDateChange(getNextDay(date));
  };

  return (
    <div className={styles.RouteTable}>
      <header>
        <h3>{title}</h3>
        <div className={styles.Datepicker}>
          <button
            type="button"
            className={`${styles.DateButton} ${styles.prev}`}
            aria-label="einen Tag früher"
            onClick={handlePrevClick}
          ></button>
          <DatePicker
            locale="de"
            selected={date}
            onChange={onDateChange}
            dateFormat="dd.MM.yyyy"
            minDate={minDate}
            className={styles.DateInput}
            ref={dateInputRef}
          />
          <button
            type="button"
            className={`${styles.DateButton} ${styles.calendar}`}
            aria-label="Datum auswählen"
            onClick={handleCalendarClick}
          ></button>
          <button
            type="button"
            className={`${styles.DateButton} ${styles.next}`}
            aria-label="einen Tag später"
            onClick={handleNextClick}
          ></button>
        </div>
      </header>
      {routes && routes.length > 0 ? (
        <ul className={styles.Routes}>
          {routes.map((route: any) => (
            <Route key={route.id} data={route} />
          ))}
        </ul>
      ) : (
        <p>Es sind leider keine Fahrten verfügbar</p>
      )}
    </div>
  );
};

export default RouteTable;
