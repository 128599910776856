import React, { FC } from 'react';
import styles from './Loading.module.css';

export const LoadingEllipsis: FC<{
  color?: string;
  size?: number;
  classes?: string;
}> = React.memo(({ color = '#9aca98', size = 40, classes = '' }) => {
  return (
    <div
      className={`${styles.Ellipsis} ${classes}`}
      style={{ width: size, height: size }}
    >
      <div style={{ backgroundColor: `${color}` }}></div>
      <div style={{ backgroundColor: `${color}` }}></div>
      <div style={{ backgroundColor: `${color}` }}></div>
      <div style={{ backgroundColor: `${color}` }}></div>
    </div>
  );
});
