import React, { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './StatusPage.module.css';
import {
  checkSupport as checkPushSupport,
  checkPermission as checkPushPermission,
  checkSubscription,
  testPushSubscription
} from 'scripts/notification';

const Loader: FC<{ loading: boolean; success: boolean; error?: string }> = ({
  loading,
  success,
  error
}) => (
  <div className={styles.LoaderWrapper}>
    {loading ? (
      <span className={styles.ClipLoader}></span>
    ) : success ? (
      <svg
        aria-hidden="true"
        width="20px"
        height="20px"
        focusable="false"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
      >
        <path
          fill="#467329"
          d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"
        ></path>
      </svg>
    ) : (
      <svg
        aria-hidden="true"
        width="20px"
        height="20px"
        focusable="false"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
      >
        <path
          fill="#73293d"
          d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"
        ></path>
      </svg>
    )}

    {!loading && !success && (
      <span style={{ marginLeft: '5px', color: '#73293d' }}>{error}</span>
    )}
  </div>
);

const TestPushPage: FC = () => {
  const [loading, setLoading] = useState(0);
  const [result1, setResult1] = useState(false);
  const [result2, setResult2] = useState(false);
  const [result3, setResult3] = useState(false);
  const [result4, setResult4] = useState(false);
  const [checkStopped, setCheckStopped] = useState(false);

  const checkSupport = async () => {
    const support = await checkPushSupport();
    setLoading(1);
    console.log('test push: support', support);
    setResult1(support);
    if (!support) setCheckStopped(true);
  };

  const checkPermission = () => {
    const permission = checkPushPermission();
    setLoading(2);
    console.log('permission', permission);
    setResult2(permission);
    if (!permission) setCheckStopped(true);
  };

  const checkSubExist = async () => {
    const response = await checkSubscription();
    setLoading(3);
    console.log('check sub exist', response);
    setResult3(response);
    if (!response) setCheckStopped(true);
  };

  useEffect(() => {
    setTimeout(checkSupport, 1000);
  }, []);

  useEffect(() => {
    if (result1) setTimeout(checkPermission, 1000);
  }, [result1]);

  useEffect(() => {
    if (result2) checkSubExist();
  }, [result2]);

  useEffect(() => {
    if (result3) setLoading(4);
  }, [result3]);

  const checkPushNotification = async () => {
    const result = await testPushSubscription();
    if (result.length > 0 && result[0].statusCode === 201) setResult4(true);
    else setResult4(false);
    setLoading(5);
    console.log('check push notification', result);
  };

  return (
    <main className={styles.Main}>
      <div className={styles.PageHeader}>
        <h1>Spiekeroog App</h1>
      </div>
      <div className={styles.Content}>
        <Link to="/" className={styles.ContentLink}>
          ← Zur Startseite
        </Link>
        <h2>Push-Benachrichtigungen Status</h2>
        <ol className={styles.CheckList}>
          <li>
            <div>
              Browser Support:&nbsp;
              <Loader
                loading={loading < 1}
                success={result1}
                error="Ihre Browserversion unterstützt die Push-Benachrichtigung nicht"
              />
            </div>
          </li>
          <li>
            <div>
              User Permission:&nbsp;
              {!(checkStopped && loading < 2) ? (
                <Loader
                  loading={loading < 2}
                  success={result2}
                  error="Browser Push-Benachrichtigung für die Seite sind deaktiviert"
                />
              ) : (
                <span>Not applicable</span>
              )}
            </div>
          </li>
          <li>
            <div>
              Subscription exists:&nbsp;
              {!(checkStopped && loading < 3) ? (
                <Loader
                  loading={loading < 3}
                  success={result3}
                  error="Die Subscription wurde nicht erstellt"
                />
              ) : (
                <span>Not applicable</span>
              )}
            </div>
          </li>
          <li>
            <div>
              Test-Nachricht senden:&nbsp;
              {!checkStopped ? (
                loading < 4 ? (
                  <Loader loading={true} success={true} />
                ) : loading === 4 ? (
                  <button
                    className={`button ${styles.CheckAction}`}
                    onClick={checkPushNotification}
                  >
                    Eine Test-Nachricht senden
                  </button>
                ) : (
                  <Loader
                    loading={false}
                    success={result4}
                    error="Es ist ein Fehler aufgetreten "
                  />
                )
              ) : (
                <span>Not applicable</span>
              )}
            </div>
          </li>
          {loading > 4 && result4 && (
            <li>
              <div>
                Keine Benachrichtigung bekommen oder ein Problem
                aufgetreten?&nbsp;
                <a
                  href="mailto:info@spiekeroog.de"
                  className={`button ${styles.CheckAction}`}
                >
                  Problem melden
                </a>
              </div>
            </li>
          )}
        </ol>
      </div>
    </main>
  );
};

export default TestPushPage;
