import React, { FC, useEffect, useState } from 'react';
import styles from './NotificationSection.module.css';
import useStrapiAPI from 'hooks/useStrapiApi';
import { Message } from 'types';
import { LoadingEllipsis as Loading } from 'components/Loading/LoadingEllipsis';

const NotificationSection: FC<{
  title: string;
  type: number;
}> = ({ title, type }) => {
  const [messages, setMessages] = useState([]);
  const [{ response, loading, error }, callAPI] = useStrapiAPI();

  useEffect(() => {
    const today = new Date();
    today.setHours(0, 0, 0);
    callAPI('/messages', 'GET', { type_eq: type, happeningDate_gte: today });
  }, [callAPI, type]);

  useEffect(() => {
    if (response && response.data) {
      setMessages(response.data);
    }
  }, [response]);

  return (
    <section className={styles.Notifications}>
      <h2>{title}</h2>
      {loading ? (
        <Loading />
      ) : (
        messages &&
        (messages.length > 0 ? (
          <ul className={styles.MessageList}>
            {messages.map((message: Message) => (
              <li key={message.id} className={styles.Message}>
                <h4 className={styles.MessageTitle}>{message.Title}</h4>
                <p className={styles.MessageBody}>{message.Text}</p>
              </li>
            ))}
          </ul>
        ) : !error ? (
          <p>Es gibt keine aktuelle Meldungen</p>
        ) : (
          <p className="error-text">{error}</p>
        ))
      )}
    </section>
  );
};

export default NotificationSection;
